import React from "react";

import "./LinkFooter.scss";

export default function MentionsLegales() {
    return (
        <div className="main-para">
            <h1>Mentions légales</h1>

            <h2>Éditeur du site</h2>
            <p>
                JeuxSolo.com est édité par Guillaume de Pereyra, une entreprise
                individuelle située 10 Grand rue 86470 Lavausseau, enregistrée
                sous le numéro SIREN 838120947, et sous le numéro TVA
                FR51838120947. L’entreprise peut être contactée par téléphone au
                0612345786 ou par courrier électronique à l’adresse
                gdepereyra@yahoo.fr. Le directeur de la publication du site
                Jeuxsolo.com est Guillaume de Pereyra.
            </p>

            <h2>Hébergeur du site</h2>
            <p>
                Ce site est hébergé par OVH, une SAS dont le siège social est
                situé 2 rue Kellermann 59100 Roubaix, et qui est enregistrée
                sous le numéro TVA FR22424761419.
            </p>

            <h2>Affiliation</h2>
            <p>
                Certaines pages de Jeuxsolo.com contiennent des liens
                d'affiliation vers des produits vendus sur les sites commerciaux
                amazon.fr, philibernet.com, drivethrurpg.com, et itch.io. Les
                liens d’affiliation apparaissent sur Jeuxsolo.com sous la forme
                de boutons cliquables explicitant le nom du site affilié (les
                intitulés des boutons sont respectivement « Amazon », «
                Philibert », « Dtrpg », « Itch.io »). Cela signifie que si vous
                cliquez sur l’un de ces liens, vous serez renvoyé vers la page
                permettant d’acheter le produit concerné sur le site
                correspondant au bouton. Si vous effectuez un achat, nous
                recevrons une commission de l’ordre de 5% du prix de vente. Cela
                n'affecte pas le prix que vous payez. Cette commission nous
                permet de financer l’hébergement du site et de rémunérer
                l’activité de rédaction d’articles. Les conditions de vente des
                produits sont celles des sites sur lesquelles ils sont vendus,
                et n’engagent pas la responsabilité de Jeuxsolo.com en tant que
                vendeur. Les boutons cliquables renvoyant vers d’autres sites
                que les quatre sites commerciaux sus-mentionnés, intitulés «
                externe », ne sont pas des liens d’affiliation, et n’engendrent
                aucune commission en cas de vente
            </p>

            <h2>Politique de confidentialité</h2>
            <p>
                La politique de confidentialité et d’utilisation des données à
                caractère personnel du service OVHcloud qui héberge le site
                Jeuxsolo.com peut être consultée via le lien suivant :
                https://www.ovhcloud.com/fr/terms-and-conditions/privacy-policy/
                L’entreprise individuelle Guillaume de Pereyra qui édite le site
                Jeuxsolo.com n’effectue en elle-même aucune exploitation ou
                revente de données à caractère personnel. Aucune communication
                explicite de données à caractère personnel par l’utilisateur
                n’est requise pour la consultation de l’ensemble des pages du
                site Jeuxsolo.com. En cas de contact avec l’éditeur
                (guillaume@jeuxsolo.com) ou avec le développeur du site
                Jeuxsolo.com (baptiste@jeuxsolo.com), les adresses électroniques
                utilisées pour la prise de contact ainsi que le contenu des
                messages de contact seront conservés sans limite de temps sur
                les boîtes électroniques contactées, mais ne seront exploitées
                par l’entreprise individuelle Guillaume de Pereyra qui édite le
                site Jeuxsolo.com à aucune autre fin que la communication
                directe entre l’utilisateur et l’éditeur ou le développeur
                contacté. En cas de simple demande par l’utilisateur, le contenu
                de ses messages de contact ainsi que les adresses e-mail qu’il a
                utilisées pour la prise de contact seront effacés des boîtes
                e-mail contactées.
            </p>

            <h2>Droits d'auteur et marques déposées</h2>
            <p>
                Tous les droits d'auteur et les marques déposées mentionnés sur
                ce site appartiennent à leurs propriétaires respectifs. Tous les
                jeux présentés sur le site Jeuxsolo.com avec la mention «
                traduit par Jeuxsolo » sur leur vignette (image du jeu) ont été
                traduits avec l’accord explicite de leurs auteurs par Guillaume
                de Pereyra. Ces traductions n’ont fait l’objet d’aucune
                rémunération, et constituent une activité bénévole. Les auteurs
                restent intégralement propriétaires et seuls exploitants légaux
                de la version traduite en Français de leurs jeux.
            </p>

            <h2>Limitation de responsabilité</h2>
            <p>
                Bien que nous nous efforcions d'assurer l'exactitude des
                informations sur ce site, nous ne pouvons pas garantir que les
                informations sont à jour, précises ou complètes. Nous ne sommes
                pas responsables des erreurs ou omissions, ni des conséquences
                qui pourraient en résulter. L'utilisation de ce site et de tout
                contenu ou lien est à vos propres risques.
            </p>
        </div>
    );
}
