import React from "react";
import { Link } from "react-router-dom";
import AffiliateButton from "../../Components/Buttons/AffiliateButton";

import "../../Styles/GameCardHome.scss";

const GameCardHome = ({
    jeu,
    newGameThreshold,
    children,
    gameType,
    gameId,
}) => (
    <div className="wrapper">
        <div className="card">
            <img src={jeu.cover} alt={jeu.titre} />
            {jeu.traduction && (
                <div className="trad_ribbon">Traduit par JeuxSolo</div>
            )}
            {new Date(jeu.createdAt) >= newGameThreshold && (
                <div className="ribbon">Nouveauté</div>
            )}

            {/* {jeu.traduction && (
                <div className="circle-flag">
                    <img src="images/logo_jeux_solo.png" alt="Logo Jeuxsolo" />
                </div>
            )} */}
            <div className="info">
                <Link
                    to={`/${gameType}/${gameId}-${jeu.titre}`}
                    className="button"
                    rel="noreferrer"
                >
                    <h1>{jeu.titre}</h1>
                    {jeu.traduction && (
                        <div className="trad_message">
                            <p>
                                <span class="text">Traduit par JeuxSolo</span>
                            </p>
                        </div>
                    )}
                    <p>{jeu.resume}</p>
                    {/* <div className="trad_ribbon">Traduit par JeuxSolo</div> */}
                </Link>
                <div className="button-container">
                    <AffiliateButton
                        className="button"
                        game={jeu}
                        text="Amazon"
                        bgcolor="#ffa600"
                        color="#000000"
                        border="#ffa600"
                        href={jeu.lien_affiliation_amazon}
                    />
                    <AffiliateButton
                        className="button"
                        jeu={jeu}
                        text="Philibert"
                        bgcolor="#f6871e"
                        color="#000000"
                        border="#f6871e"
                        href={jeu.lien_affiliation_philibert}
                    />
                    <AffiliateButton
                        className="button"
                        jeu={jeu}
                        text="Dtrpg"
                        bgcolor="#cb0e00"
                        color="#000000"
                        border="#f6871e"
                        href={jeu.lien_affiliation_drivethrurpg}
                    />
                    <AffiliateButton
                        className="button"
                        jeu={jeu}
                        text="Itch.io"
                        bgcolor="#FFD700 "
                        color="#000000"
                        border="#FFD700 "
                        href={jeu.lien_affiliation_itchio}
                    />
                    <AffiliateButton
                        className="button"
                        jeu={jeu}
                        text="Externe"
                        bgcolor="#f6871e"
                        color="#000000"
                        border="#f6871e"
                        href={jeu.lien_affiliation_externe}
                    />
                </div>
            </div>
        </div>
    </div>
);

export default GameCardHome;
