import React from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import "./Form.scss";
import "../../Styles/EditGameForm.scss";

function AddUniversForm() {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    const onSubmit = async (data) => {
        try {
            await axios.post("https://api.jeuxsolo.com/admin/univers", data);
            reset();
            alert("L'univers a bien été créée!");
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <nav className="form-button">
                <select onChange={(e) => navigate(e.target.value)}>
                    <option value="">Ajouter un jeu</option>
                    <option value="/form/jeux-de-role">Jeux de rôle</option>
                    <option value="/form/jeux-de-plateau">
                        Jeux de plateau
                    </option>
                    <option value="/form/livres-jeux">Livres-Jeux</option>
                    <option value="/form/journaling">Journaling</option>
                    <option value="/form/outils-de-creation">
                        Outils de création
                    </option>
                    <option value="/form/materiel">Matériel</option>
                    <option value="/form/auteur">Créer un auteur</option>
                    <option value="/form/univers">Créer un Univers</option>
                </select>
            </nav>

            <nav className="edit-form-buttons">
                <select onChange={(e) => navigate(e.target.value)}>
                    <option value="">Editer un jeu</option>
                    <option value="/form/edit-jeux-de-role">
                        Edit Jeux de rôle
                    </option>
                    <option value="/form/edit-jeux-de-plateau">
                        Edit Jeux de plateau
                    </option>
                    <option value="/form/edit-livres-jeu">
                        Edit Livres-Jeux
                    </option>
                    <option value="/form/edit-journaling">
                        Edit Journaling
                    </option>
                    <option value="/form/edit-outils-de-creation">
                        Edit Outils de création
                    </option>
                    <option value="/form/materiel">Edit Matériel</option>
                    <option value="/form/auteur">Edit Créer un auteur</option>
                    <option value="/form/univers">Edit Créer un Univers</option>
                </select>
            </nav>
            <form className="game-form" onSubmit={handleSubmit(onSubmit)}>
                <h1>Ajouter un univers</h1>

                <label>
                    Nom de l'univers
                    <input
                        type="text"
                        {...register("nom_univers", { required: true })}
                    />
                    {errors.nom_univers && <p>Ce champ est requis</p>}
                </label>
                <button type="submit">Ajouter l'univers</button>
            </form>
        </>
    );
}

export default AddUniversForm;
