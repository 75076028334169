/** @format */

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useInterval } from 'react-use';
import GameCard from '../../Components/GameCard/GameCard';
// import AffiliateButton from "../../Components/Buttons/AffiliateButton";
import '../../Styles/HomePage.scss';

function OutilsDeCreation() {
    const [games, setGames] = useState([]);
    const [searchGame, setSearchGame] = useState('');
    const [searchAuthor, setSearchAuthor] = useState('');
    const [selectedUnivers, setSelectedUnivers] = useState(null);
    // Date seuil pour déterminer si un jeu est une nouveauté (30 jours)
    const newGameThreshold = new Date();
    newGameThreshold.setDate(newGameThreshold.getDate() - 30);

    useEffect(() => {
        axios
            .get('https://api.jeuxsolo.com/outils-de-creation')
            .then((response) => {
                setGames(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const filteredGames = games.filter((game) => {
        const authorName =
            game.JournalingAuteur && Array.isArray(game.JournalingAuteur) && game.JournalingAuteur.length > 0
                ? game.JournalingAuteur[0].auteur.nom_auteur || ''
                : '';

        return (
            game.titre.toLowerCase().includes(searchGame.toLowerCase()) &&
            authorName.toLowerCase().includes(searchAuthor.toLowerCase()) &&
            (!selectedUnivers || (game.Univers && game.Univers.id_univers === selectedUnivers))
        );
    });

    return (
        <main>
            <Helmet>
                <title>Matériel pour jeux solo - JeuxSolo</title>
                <meta
                    name="description"
                    content="Découvrez notre sélection de matériel pour jeux solo. Améliorez votre expérience de jeu avec des accessoires de qualité. Nouveaux articles ajoutés régulièrement."
                />
                <meta name="keywords" content="jeux, solo, jeux solo, matériel de jeux, nouveautés" />
                <meta name="author" content="Guillaume, Baptiste" />
            </Helmet>
            <div className="home-container">
                <div className="container">
                    <div className="search_container">
                        <h1>Matériel</h1>
                        <div className="search-bar">
                            <input
                                type="text"
                                placeholder="Chercher un matériel"
                                value={searchGame}
                                onChange={(e) => setSearchGame(e.target.value)}
                            />
                        </div>
                    </div>
                    {filteredGames.map((game) => (
                        <div className="game_card_container">
                            <GameCard
                                key={game.id_journaling}
                                jeu={game}
                                newGameThreshold={newGameThreshold}
                                category="jeuxdeplateau"
                            >
                                <div className="game_card_authors">
                                    {game.JournalingAuteur &&
                                        game.JournalingAuteur.map((jeuAuteur) => (
                                            <p key={jeuAuteur.auteur.id_auteur}>{jeuAuteur.auteur.nom_auteur}</p>
                                        ))}
                                </div>
                            </GameCard>
                        </div>
                    ))}
                </div>
            </div>
        </main>
    );
}

export default OutilsDeCreation;
