import React from "react";
import { Helmet } from "react-helmet";

import "./LinkFooter.scss";

export default function LivresJeuxFooter() {
    return (
        <>
            <Helmet>
                <title>C'est quoi un livre-jeux - JeuxSolo</title>
                <meta
                    name="description"
                    content="Vivez des aventures immersives et passionnantes à votre rythme avec les livres-jeux jeuxsolo !."
                />
                <meta
                    name="keywords"
                    content="jeux, solo, jeux solo, nouveautés, livres-jeux, jeux de stratégie"
                />
                <meta name="author" content="Guillaume, Baptiste" />
            </Helmet>
            <div className="main-para">
                <h1>Livres-jeux</h1>
                <p>
                    Un livre-jeu a pour support principal un livre. Il peut
                    éventuellement s’accompagner de supports externes comme une
                    carte ou des documents. Un livre-jeu présente{" "}
                    <span className="bold_text">
                        une narration dont le joueur influencera le déroulement
                        par ses choix
                    </span>
                    . Contrairement à un jeu de rôle solo, toutes les
                    possibilités de l’histoire sont pré-écrites.
                </p>

                <p>
                    La durée d’un livre-jeu est généralement{" "}
                    <span className="bold_text">
                        d’une à dix heures selon sa difficulté
                    </span>
                    . Il peut proposer une histoire isolée, ou une partie d’une
                    saga (histoire en plusieurs livres).
                </p>
            </div>
        </>
    );
}
