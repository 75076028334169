import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import parse from "html-react-parser";

function InfoModal() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button variant="info" onClick={handleShow}>
                A lire !
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>HTML Les basiques à connaitre !</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {parse(`
                    <h6>Salut mon Guitoo ! Quelques balises pour t'aider à formater ton texte ;)</h6>
            <p><b>Balises de texte :</b></p>
            <ul>
            <li><b>&lt;h1&gt; à &lt;h6&gt; :</b> Ces balises sont utilisées pour définir les titres et les sous-titres, &lt;h1&gt; étant le niveau le plus élevé (et généralement le plus grand) et &lt;h6&gt; étant le niveau le plus bas (et généralement le plus petit).</li>
            <li><b>&lt;p&gt; :</b> Cette balise est utilisée pour définir un paragraphe.</li>
            <li><a href="https://developer.mozilla.org/fr/docs/Web/HTML/Element/Heading_Elements" target="blank">Un lien vers la doc ;)</a></li>
            <!-- Ajoutez plus de balises ici comme ci-dessus -->
            </ul>
            <p><b>Balises pour les images :</b></p>
            <ul>
            <li><b>&lt;img&gt; :</b> Cette balise est utilisée pour intégrer une image dans une page web. Les attributs importants sont src (l'URL de l'image) et alt (le texte alternatif qui s'affiche si l'image ne peut pas être chargée).</li>
            <li><a href="https://developer.mozilla.org/fr/docs/Web/HTML/Element/img" target="blank">Un lien vers la doc ;)</a></li>
            <!-- Ajoutez plus de balises ici comme ci-dessus -->
            </ul>
            <p><b>Autres balises utiles pour la mise en forme :</b></p>
            <ul>
            <li><b>&lt;div&gt; :</b> Cette balise est utilisée comme un conteneur générique pour d'autres éléments.</li>
            <li><a href="https://www.youtube.com/watch?v=w2knKi0ZQps" target="blank">Un lien tuto vidéo si jamais</a></li>
            <!-- Ajoutez plus de balises ici comme ci-dessus -->
            
            </ul>`)}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default InfoModal;
