import React from "react";
import "./InBuilding.scss";

const InBuilding = () => {
    return (
        <div className="construction-page">
            <div className="content">
                <h1>Page en construction</h1>
                <p>
                    Nous travaillons dur pour vous apporter cette
                    fonctionnalité. Revenez bientôt !
                </p>
            </div>
        </div>
    );
};

export default InBuilding;
