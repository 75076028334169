import React from "react";
import { Routes, Route } from "react-router-dom";

import AddGameForm from "../../Components/Form/AddGameForm";
import PublicLayout from "../PublicRouter/PublicLayout";

export default function AdminRouter() {
    return (
        <Routes>
            <Route
                path="/form"
                element={
                    <PublicLayout>
                        <AddGameForm />
                    </PublicLayout>
                }
            />
        </Routes>
    );
}
