import React from "react";
import { Helmet } from "react-helmet";

import "./LinkFooter.scss";

export default function Contact() {
    return (
        <div className="main-para">
            <Helmet>
                <title>
                    Contactez JeuxSolo - Nous sommes là pour vous aider
                </title>
                <meta
                    name="description"
                    content="Contactez-nous pour toute question ou suggestion concernant JeuxSolo. Nous sommes toujours ravis de recevoir des nouvelles de notre communauté de jeux solo."
                />
                <meta
                    name="keywords"
                    content="JeuxSolo, contact, soutien, Guillaume De pereyra, Baptiste Fleury, jeux de rôle solo, jeux de plateau solo, livres-jeux, journaling, jeux de stratégie, traductions de jeux"
                />
                <meta
                    name="author"
                    content="Guillaume De pereyra, Baptiste Fleury"
                />
            </Helmet>

            <h2>Contactez-nous</h2>
            <p>
                Si vous avez des questions ou des envies à partager, n’hésitez
                pas à nous contacter :
            </p>
            <ul>
                <li>guillaume@jeuxsolo.fr (articles et traductions)</li>
                <li>baptiste@jeuxsolo.fr (création et maintenance du site)</li>
            </ul>
        </div>
    );
}
