import { useForm } from "react-hook-form";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "./Form.scss";

function AddAuthorForm() {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    const onSubmit = async (data) => {
        try {
            const authorToSubmit = {
                nom_auteur: data.nom_auteur,
            };

            await axios.post(
                "https://api.jeuxsolo.com/admin/auteurs",
                authorToSubmit,
            );
            reset();
            alert("L'auteur a bien été créé!");
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <nav className="form-button">
                <select onChange={(e) => navigate(e.target.value)}>
                    <option value="">Ajouter un jeu</option>
                    <option value="/form/jeux-de-role">Jeux de rôle</option>
                    <option value="/form/jeux-de-plateau">
                        Jeux de plateau
                    </option>
                    <option value="/form/livres-jeux">Livres-Jeux</option>
                    <option value="/form/journaling">Journaling</option>
                    <option value="/form/outils-de-creation">
                        Outils de création
                    </option>
                    <option value="/form/materiel">Matériel</option>
                    <option value="/form/auteur">Créer un auteur</option>
                    <option value="/form/univers">Créer un Univers</option>
                </select>
            </nav>

            <nav className="edit-form-buttons">
                <select onChange={(e) => navigate(e.target.value)}>
                    <option value="">Editer un jeu</option>
                    <option value="/form/edit-jeux-de-role">
                        Edit Jeux de rôle
                    </option>
                    <option value="/form/edit-jeux-de-plateau">
                        Edit Jeux de plateau
                    </option>
                    <option value="/form/edit-livres-jeu">
                        Edit Livres-Jeux
                    </option>
                    <option value="/form/edit-journaling">
                        Edit Journaling
                    </option>
                    <option value="/form/edit-outils-de-creation">
                        Edit Outils de création
                    </option>
                    <option value="/form/materiel">Edit Matériel</option>
                    <option value="/form/auteur">Edit Créer un auteur</option>
                    <option value="/form/univers">Edit Créer un Univers</option>
                </select>
            </nav>
            <form className="game-form" onSubmit={handleSubmit(onSubmit)}>
                <h1>Ajouter un Auteur</h1>

                <label>
                    Nom d'auteur
                    <input
                        type="text"
                        {...register("nom_auteur", { required: true })}
                    />
                    {errors.nom_auteur && <p>Ce champ est requis</p>}
                </label>
                <button type="submit">Ajouter l'auteur</button>
            </form>
        </>
    );
}

export default AddAuthorForm;
