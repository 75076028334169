import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";
import AffiliateButton from "../../Buttons/AffiliateButton";
import parse from "html-react-parser";

import "../../../Styles/GameDetails.scss";

function GameDetail() {
    const { id_jeu } = useParams();
    const [game, setGame] = useState(null);

    useEffect(() => {
        axios
            .get(`https://api.jeuxsolo.com/jeux/${id_jeu}`)
            .then((response) => {
                setGame(response.data);
                document.title = `Découvrez ${response.data.titre} - JeuxSolo`;
            })
            .catch((error) => {
                console.error(error);
            });
    }, [id_jeu]);

    if (!game) {
        return (
            <Spinner animation="border" role="status">
                <span className="sr-only"></span>
            </Spinner>
        );
    }

    // Fonction pour extraire les mots-clés des balises HTML du contenu
    const extractKeywords = (htmlContent) => {
        let keywords = [];
        const domParser = new DOMParser();
        const doc = domParser.parseFromString(htmlContent, "text/html");
        const headings = doc.querySelectorAll("h1, h4, a");

        headings.forEach((heading) => {
            const textContent = heading.textContent.trim();
            if (textContent) {
                const words = textContent.split(/\s+/);
                keywords.push(...words);
            }
        });

        return keywords.join(", ");
    };

    // Extraire les mots-clés du contenu HTML
    let keywords = extractKeywords(game.description);

    return (
        <div className="game-detail">
            <Helmet>
                <title>{`Découvrez ${game.titre} - JeuxSolo`}</title>
                <meta name="description" content={game.description} />
                <meta name="keywords" content={keywords} />
                <meta name="author" content="Guillaume, Baptiste" />
                {/* Autres métadonnées si nécessaire */}
            </Helmet>
            <h1>{game.titre}</h1>
            <img src={game.cover} alt={game.titre} />
            <div className="button-container">
                <AffiliateButton
                    className="button"
                    game={game}
                    text="Amazon"
                    bgcolor="#ffa600"
                    color="#000000"
                    border="#ffa600"
                    href={game.lien_affiliation_amazon}
                />
                <AffiliateButton
                    className="button"
                    game={game}
                    text="Philibert"
                    bgcolor="#f6871e"
                    color="#000000"
                    border="#f6871e"
                    href={game.lien_affiliation_philibert}
                />
                <AffiliateButton
                    className="button"
                    game={game}
                    text="Dtrpg"
                    bgcolor="#cb0e00"
                    color="#000000"
                    border="#f6871e"
                    href={game.lien_affiliation_drivethrurpg}
                />
                <AffiliateButton
                    className="button"
                    game={game}
                    text="Itch.io"
                    bgcolor="#FFD700 "
                    color="#000000"
                    border="#FFD700 "
                    href={game.lien_affiliation_itchio}
                />
                <AffiliateButton
                    className="button"
                    game={game}
                    text="Externe"
                    bgcolor="#f6871e"
                    color="#000000"
                    border="#f6871e"
                    href={game.lien_affiliation_externe}
                />
            </div>
            {/* <p className="game-description">
                {game.description && parse(game.description)}
            </p> */}
            <article className="game-description">
                {game.description && parse(game.description)}
            </article>

            <div className="button-container">
                <AffiliateButton
                    className="button"
                    game={game}
                    text="Amazon"
                    bgcolor="#ffa600"
                    color="#000000"
                    border="#ffa600"
                    href={game.lien_affiliation_amazon}
                />
                <AffiliateButton
                    className="button"
                    game={game}
                    text="Philibert"
                    bgcolor="#f6871e"
                    color="#000000"
                    border="#f6871e"
                    href={game.lien_affiliation_philibert}
                />
                <AffiliateButton
                    className="button"
                    game={game}
                    text="Dtrpg"
                    bgcolor="#cb0e00"
                    color="#000000"
                    border="#f6871e"
                    href={game.lien_affiliation_drivethrurpg}
                />
                <AffiliateButton
                    className="button"
                    game={game}
                    text="Itch.io"
                    bgcolor="#FFD700 "
                    color="#000000"
                    border="#FFD700 "
                    href={game.lien_affiliation_itchio}
                />
                <AffiliateButton
                    className="button"
                    game={game}
                    text="Externe"
                    bgcolor="#f6871e"
                    color="#000000"
                    border="#f6871e"
                    href={game.lien_affiliation_externe}
                />
            </div>
        </div>
    );
}

export default GameDetail;
