/** @format */

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import GameCard from '../../Components/GameCard/GameCard';
import '../../Styles/HomePage.scss';

// Fonction principale du composant
function LivresJeux() {
    // Variables d'état pour stocker les jeux avec les auteurs, les requêtes de recherche, l'univers sélectionné et univers
    const [gamesWithAuthors, setGamesWithAuthors] = useState([]);
    const [searchGame, setSearchGame] = useState('');
    const [searchAuthor, setSearchAuthor] = useState('');
    const [selectedUnivers, setSelectedUnivers] = useState(null);
    const [univers, setUnivers] = useState([]);
    // Date seuil pour déterminer si un jeu est une nouveauté (30 jours)
    const newGameThreshold = new Date();
    newGameThreshold.setDate(newGameThreshold.getDate() - 30);

    // Utiliser l'effet pour effectuer une demande HTTP au chargement du composant
    useEffect(() => {
        // Obtenir les données de jeu
        axios
            .get('https://api.jeuxsolo.com/livresjeux')
            .then((response) => {
                const gamesData = response.data;
                // Obtenir les auteurs pour chaque jeu
                const promises = gamesData.map((game) =>
                    axios.get(`https://api.jeuxsolo.com/livresjeux/${game.id_livreJeux}/auteurs`),
                );
                // Une fois que toutes les requêtes pour les auteurs sont terminées
                Promise.all(promises).then((authorsResponses) => {
                    // Ajouter les auteurs aux données du jeu
                    const gamesWithAuthorsData = gamesData.map((game, index) => {
                        const gameWithAuthors = {
                            ...game,
                            authors: authorsResponses[index].data,
                        };
                        return gameWithAuthors;
                    });
                    setGamesWithAuthors(gamesWithAuthorsData);
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    // Utiliser l'effet pour obtenir les données d'univers au chargement du composant
    useEffect(() => {
        axios
            .get('https://api.jeuxsolo.com/univers')
            .then((response) => {
                setUnivers(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    // Filtrer les jeux en fonction de la recherche et de l'univers sélectionné
    const filteredGames = gamesWithAuthors.filter((game) => {
        const authorName =
            game.authors && Array.isArray(game.authors) && game.authors.length > 0
                ? game.authors[0].nom_auteur || ''
                : '';

        return (
            game.titre.toLowerCase().includes(searchGame.toLowerCase()) &&
            authorName.toLowerCase().includes(searchAuthor.toLowerCase()) &&
            (!selectedUnivers || (game.Univers && game.Univers.id_univers === selectedUnivers))
        );
    });

    // Rendre le composant
    return (
        <main>
            <Helmet>
                <title>Livres jeux solo - JeuxSolo</title>
                <meta
                    name="description"
                    content="Plongez dans nos livres jeux solo où vous êtes le héros. Décidez de votre destin et vivez des histoires captivantes. Nouveaux livres ajoutés régulièrement."
                />
                <meta
                    name="keywords"
                    content="jeux, solo, jeux solo, livres jeux, livres dont vous êtes le héros, nouveautés"
                />
                <meta name="author" content="Guillaume, Baptiste" />
            </Helmet>
            <div className="home-container">
                <div className="container">
                    <div className="search_container">
                        <h1>Livres-jeux</h1>
                        <div className="search-bar">
                            <input
                                type="text"
                                placeholder="Recherche par livre jeu..."
                                value={searchGame}
                                onChange={(e) => setSearchGame(e.target.value)}
                            />
                            <input
                                type="text"
                                placeholder="Recherche par auteur..."
                                value={searchAuthor}
                                onChange={(e) => setSearchAuthor(e.target.value)}
                            />
                            <select
                                value={selectedUnivers}
                                onChange={(e) => setSelectedUnivers(parseInt(e.target.value))}
                            >
                                <option value="">univers</option>
                                {univers.map((univers) => (
                                    <option key={univers.id_univers} value={univers.id_univers}>
                                        {univers.nom_univers}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    {filteredGames.map((game) => (
                        <div className="game_card_container">
                            <GameCard
                                key={game.id_livreJeux}
                                id_jeu={game.id_livreJeux}
                                cover={game.cover}
                                titre={game.titre}
                                resume={game.resume}
                                createdAt={game.createdAt}
                                newGameThreshold={newGameThreshold}
                                category="livres-jeux"
                                gameId={game.id_livreJeux}
                                difficulte={game.difficulte}
                                traduction={game.traduction}
                                authors={
                                    game.LivresJeuxAuteur && Array.isArray(game.LivresJeuxAuteur)
                                        ? game.LivresJeuxAuteur.map((journalAuteur) => journalAuteur.auteur.nom_auteur)
                                        : []
                                }
                                annee_sortie={game.annee_sortie}
                                univers={univers.find((universItem) => universItem.id_univers === game.id_univers)}
                                lien_affiliation_amazon={game.lien_affiliation_amazon}
                                lien_affiliation_philibert={game.lien_affiliation_philibert}
                                lien_affiliation_drivethrurpg={game.lien_affiliation_drivethrurpg}
                                lien_affiliation_itchio={game.lien_affiliation_itchio}
                                lien_affiliation_externe={game.lien_affiliation_externe}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </main>
    );
}

export default LivresJeux;
