import React from "react";
import { FaDiscord, FaFacebook } from "react-icons/fa";

import { Link } from "react-router-dom";
import "./Footer.scss";

function Footer() {
    return (
        <footer className="footer">
            <div className="text-center">
                <div className="row">
                    <div className="col-md-4">
                        <ul className="list-unstyled">
                            <h4>À propos de nous</h4>
                            <li>
                                <Link to="/jeux-solo-project">
                                    Le projet JeuxSolo
                                </Link>
                            </li>
                            {/* <li>
                                <Link to="/contact">Contact</Link>
                            </li> */}
                            <li>
                                <Link to="/mentions-legales">
                                    Mentions Légales
                                </Link>
                            </li>
                        </ul>
                        {/* <p>
                            Nous sommes une boutique de passionnés spécialisés
                            dans la vente de jeux de rôles solo.
                        </p> */}
                    </div>
                    <div className="col-md-4 col-xs-6">
                        <h4>Les types de jeux</h4>

                        <ul className="list-unstyled">
                            <li>
                                <Link to="/jeu-de-plateau-tuto">
                                    Jeux de plateau
                                </Link>
                            </li>
                            <li>
                                <Link to="/livre-jeux-tuto">Livres-Jeux</Link>
                            </li>
                            <li>
                                <Link to="/jeu-de-role-tuto">Jeux de rôle</Link>
                            </li>

                            <li>
                                <Link to="journaling-tuto">Journaling</Link>
                            </li>
                        </ul>
                    </div>
                    {/* <div className="col-md-2 col-xs-6">
                        <h4>L'Inventaire</h4>
                        <ul className="list-unstyled">
                            <li>
                                <Link to="/outils-de-creation">
                                    Outils de création
                                </Link>
                            </li>
                            <li>
                                <Link to="/materiel">Matériel</Link>
                            </li>
                        </ul>
                    </div> */}
                    <div className="col-md-4">
                        <h4>Contactez-nous</h4>
                        <ul className="contact_us">
                            <p>Rédacteur & Traducteur</p>
                            <li className="contact_email">
                                <i className="fa fa-envelope-o"></i>{" "}
                                guillaume@jeuxsolo.com
                            </li>

                            <li class="social-icons">
                                <Link className="discord" to="https://discord.com/channels/1080953309071937566/1175072409250971728">
                                    <FaDiscord  />
                                </Link>
                                {/* <Link className="facebook" to="https://www.facebook.com/profile.php?id=61552916702247">
                                    <FaInstagram />
                                </Link> */}
                                <Link className="youtube" to="https://www.facebook.com/profile.php?id=61552916702247">
                                    <FaFacebook />
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                
                <hr />
                <p className="text-muted">
                    © 2023 JeuxSolo - Tous droits réservés
                </p>
            </div>
        </footer>
    );
}

export default Footer;
