import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";

import "../../Styles/AffiliateButtons.scss";

const AffiliateButton = ({ jeu, text, bgcolor, color, border, href }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        href && (
            <Button
                href={href}
                target="_blank"
                className="affiliate-button"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={{
                    backgroundColor: bgcolor,
                    borderColor: border,
                    color: isHovered ? "white" : color,
                }}
            >
                <FontAwesomeIcon
                    icon={faShoppingCart}
                    style={{ marginRight: "5px" }}
                />
                {text}
            </Button>
        )
    );
};

export default AffiliateButton;
