/** @format */

import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import '../Styles/HomePage.scss';

import { useState, useEffect } from 'react';
import axios from 'axios';

import GameCardHome from '../Components/GameCard/GameCardHome';

function Jeux() {
    const [jeux, setJeux] = useState([]);
    const [jeuxPlateau, setJeuxPlateau] = useState([]);
    const [jeuxLivreJeu, setJeuxLivreJeu] = useState([]);
    const [jeuxJournaling, setJeuxJournaling] = useState([]);
    const [showGames, setShowGames] = useState(false);

    useEffect(() => {
        axios
            .get('https://api.jeuxsolo.com/jeux')
            .then((response) => {
                setJeux(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        axios
            .get('https://api.jeuxsolo.com/jeuxdeplateau')
            .then((response) => {
                setJeuxPlateau(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        axios
            .get('https://api.jeuxsolo.com/livresjeux')
            .then((response) => {
                setJeuxLivreJeu(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        axios
            .get('https://api.jeuxsolo.com/journaling')
            .then((response) => {
                setJeuxJournaling(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);
    // Date seuil pour déterminer si un jeu est une nouveauté (30 jours)
    const newGameThreshold = new Date();
    newGameThreshold.setDate(newGameThreshold.getDate() - 30);

    // function handleClickAmazon() {
    //   if (window.confirm("Enregistrer ce lien dans vos favoris pour soutenir le site ? Pour ajouter ce lien à vos favoris, appuyez sur Ctrl+D (Cmd+D sur Mac) et suivez les instructions de votre navigateur.")) {
    //     // L'utilisateur a confirmé, vous pouvez maintenant le rediriger vers le lien.
    //     window.location.href = "https://www.amazon.fr/?&_encoding=UTF8&tag=jeuxsolo-21&linkCode=ur2&linkId=d";
    //   }
    // }

    function addToFavorites(url, title) {
        if (window.external && 'AddFavorite' in window.external) {
            // Pour Internet Explorer
            window.external.AddFavorite(url, title);
        } else if (window.sidebar && window.sidebar.addPanel) {
            // Pour Firefox
            window.sidebar.addPanel(title, url, '');
        } else if (window.opera && window.print) {
            // Pour Opera
            const elem = document.createElement('a');
            elem.setAttribute('href', url);
            elem.setAttribute('title', title);
            elem.setAttribute('rel', 'sidebar');
            elem.click();
        } else {
            // Pour d'autres navigateurs, affichez un message invitant l'utilisateur à le faire manuellement.
            if (isMobileDevice()) {
                alert(
                    "Pour ajouter ce lien à vos favoris, veuillez ouvrir le menu de votre navigateur et sélectionner l'option 'Ajouter aux favoris' ou similaire.",
                );
            } else {
                alert(
                    'Pour ajouter ce lien à vos favoris, appuyez sur Ctrl+D (Cmd+D sur Mac) et suivez les instructions de votre navigateur.',
                );
            }
        }
    }

    function isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    // Fonction pour ajouter le lien Amazon aux favoris
    function handleClickAmazon() {
        const url = 'https://www.amazon.fr/?&_encoding=UTF8&tag=jeuxsolo-21&linkCode=ur2&linkId=d';
        const title = 'Amazon';

        if (window.confirm('Enregistrer ce lien dans vos favoris pour soutenir le site ?')) {
            addToFavorites(url, title);
        }
    }

    // Fonction pour ajouter le lien DriveThruRPG aux favoris
    function handleClickDTRPG() {
        const url = 'https://www.drivethrurpg.com/?affiliate_id=2613964';
        const title = 'DriveThruRPG';

        if (window.confirm('Enregistrer ce lien dans vos favoris pour soutenir le site ?')) {
            addToFavorites(url, title);
        }
    }

    // Fonction pour ajouter le lien Philibert aux favoris
    function handleClickPhilibert() {
        const url = 'https://www.philibertnet.com/fr/#ae837';
        const title = 'Philibert';

        if (window.confirm('Enregistrer ce lien dans vos favoris pour soutenir le site ?')) {
            addToFavorites(url, title);
        }
    }

    return (
        <>
            <Helmet>
                <title>Découvrez les jeux de rôle solo - JeuxSolo</title>
                <meta
                    name="description"
                    content="Explorez notre sélection de jeux de rôle solo. Vivez des aventures immersives et passionnantes à votre rythme. Nouveaux jeux ajoutés régulièrement."
                />
                <meta
                    name="keywords"
                    content="jeux, solo, jeux solo, jeux de rôle, nouveautés, jeux de plateau, livres-jeux, journaling, jeux de stratégie"
                />
                <meta name="author" content="Guillaume, Baptiste" />
            </Helmet>
            <main className="home-container">
                <div className="introduction">
                    <div className="paragraphs">
                        <div className="title-home">
                            <img className="logo" src="/images/logo_jeuxsolo.png" alt="logo_jeuxsolo" />
                            <h2 id="main_title">Bienvenue sur Jeuxsolo !</h2>
                            <div className="affiliation_buttons">
                                <Link
                                    className="top_link_amazon"
                                    target="blank"
                                    to="https://www.amazon.fr/?&_encoding=UTF8&tag=jeuxsolo-21&linkCode=ur2&linkId=d"
                                    onClick={handleClickAmazon}
                                >
                                    <img src="/images/Amazon.png" alt="logo amazon" className="logo_amazon" />
                                </Link>
                                <Link
                                    className="top_link_drivreThruRpg"
                                    target="blank"
                                    to="https://www.drivethrurpg.com/?affiliate_id=2613964"
                                    onClick={handleClickPhilibert}
                                >
                                    <img
                                        src="/images/drivethrurpg-logo.png"
                                        alt="logo drivethrurpg"
                                        className="logo_drivethrurpg"
                                    />
                                </Link>

                                <Link
                                    className="top_link_philibert"
                                    target="blank"
                                    to="https://www.philibertnet.com/fr/#ae837"
                                    onClick={handleClickDTRPG}
                                >
                                    <img
                                        src="/images/Logo_Philibert.png"
                                        alt="logo philibert"
                                        className="logo_philibert"
                                    />
                                </Link>
                            </div>
                        </div>
                        <div className="right">
                            <p>
                                Laissez-vous guider dans l'univers des
                                <span className="keyword"> jeux en solo</span> : des jeux immersifs qui ne nécessitent
                                personne d'autre que vous-même ! Créé et géré par Guillaume et Baptiste, passionnés
                                d’expériences ludiques, notre site vous propose une sélection soigneuse de
                                <strong> jeux variés</strong>.
                            </p>
                        </div>
                        <div className="left">
                            <p>
                                Chaque jeu est présenté via un
                                <strong> avis critique détaillé</strong>. Nous y avons inclus des conseils ou des
                                variantes afin d’optimiser votre
                                <span className="keyword"> expérience</span>. L’univers des jeux et leur complexité sont
                                aussi discutés, pour vous aider à constituer une{' '}
                                <span className="keyword">collection</span> qui vous correspond.
                            </p>
                        </div>
                        <div className="right">
                            <p>
                                Vous souhaitez découvrir des jeux qui n’ont jamais été traduits en français ? Vous êtes
                                au bon endroit ! Jeuxsolo propose régulièrement des{' '}
                                <strong>traductions officielles inédites </strong>. Suivez les nouveautés pour être dans
                                les premiers à en profiter !
                            </p>
                        </div>
                        <div className="left">
                            <p>
                                Nous facilitons aussi votre expérience d'achat en vous proposant des liens directs vers
                                des distributeurs de confiance. Plus besoin de chercher comment acquérir un jeu,{' '}
                                <strong>Jeuxsolo s’en charge !</strong>
                            </p>
                            <p>
                                Important : pour financer l’hébergement du site, nous utilisons des liens d’affiliation
                                pour la vente des jeux. Si vous cliquez sur un bouton d’achat de Jeuxsolo, nous
                                percevrons une petite commission qui ne vous coûtera rien. Vous pouvez aussi nous aider
                                si vous cliquez sur les liens en haut de page avant de faire un achat. N’hésitez pas à
                                les mettre dans vos favoris !
                            </p>
                        </div>
                        <div className="right">
                            <p>
                                Que vous soyez à la recherche de jeux de plateau, de livres-jeux, de journaling, ou de
                                jeux de rôle, notre site est conçu pour vous aider à <strong>trouver facilement</strong>{' '}
                                vos jeux préférés. Plongez dans le monde fascinant des jeux solo. Un univers de
                                passionnantes aventures dont vous serez l’auteur et le joueur !
                            </p>
                        </div>
                    </div>
                </div>

                <button className="btn-home hidden-games" onClick={() => setShowGames(!showGames)}>
                    {showGames ? 'Cacher les jeux' : 'Découvrir nos jeux'}
                </button>

                {showGames && (
                    <>
                        <h2>Les derniers jeux</h2>
                        <div className="main-container">
                            <h3>Les derniers jeux de plateau</h3>
                            <div className="container">
                                {jeuxPlateau.slice(0, 4).map((jeu) => (
                                    <div className="cardbody">
                                        <GameCardHome
                                            key={jeu.id_jeu}
                                            jeu={jeu}
                                            newGameThreshold={newGameThreshold}
                                            gameType="jeu-de-plateau"
                                            gameId={jeu.id_jeuDePlateau}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="main-container">
                            <h3>Les derniers livres-jeux</h3>
                            <div className="container">
                                {jeuxLivreJeu.slice(0, 4).map((jeu) => (
                                    <div className="cardbody">
                                        <GameCardHome
                                            key={jeu.id_jeu}
                                            jeu={jeu}
                                            newGameThreshold={newGameThreshold}
                                            gameType="livres-jeux"
                                            gameId={jeu.id_livreJeux}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="main-container">
                            <h3>Les derniers jeux de rôle</h3>
                            <div className="container">
                                {jeux.slice(0, 4).map((jeu) => (
                                    <div className="cardbody">
                                        <GameCardHome
                                            key={jeu.id_jeu}
                                            jeu={jeu}
                                            newGameThreshold={newGameThreshold}
                                            gameType="jeux"
                                            gameId={jeu.id_jeu}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="main-container">
                            <h3>Les derniers jeux de journaling</h3>
                            <div className="container">
                                {jeuxJournaling.slice(0, 4).map((jeu) => (
                                    <div className="cardbody">
                                        <GameCardHome
                                            key={jeu.id_jeu}
                                            jeu={jeu}
                                            newGameThreshold={newGameThreshold}
                                            gameType="jeu-journaling"
                                            gameId={jeu.id_journaling}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
                )}
            </main>
        </>
    );
}

export default Jeux;
