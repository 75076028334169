/** @format */

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import GameCard from '../../Components/GameCard/GameCard';
import '../../Styles/HomePage.scss';

function OutilsDeCreation() {
    const [toolsWithAuthors, setToolsWithAuthors] = useState([]);
    const [searchTool, setSearchTool] = useState('');
    const [searchAuthor, setSearchAuthor] = useState('');
    const [selectedUnivers, setSelectedUnivers] = useState(null);
    const [univers, setUnivers] = useState([]);
    // Date seuil pour déterminer si un jeu est une nouveauté (30 jours)
    const newGameThreshold = new Date();
    newGameThreshold.setDate(newGameThreshold.getDate() - 30);

    useEffect(() => {
        axios
            .get('https://api.jeuxsolo.com/outils-de-creation')
            .then((response) => {
                const toolsData = response.data;
                const promises = toolsData.map((tool) => {
                    return tool.id_outilsDeCreation
                        ? axios.get(`https://api.jeuxsolo.com/outils-de-creation/${tool.id_outilsDeCreation}/auteurs`)
                        : Promise.resolve({ data: [] });
                });
                Promise.all(promises).then((authorsResponses) => {
                    const toolsWithAuthorsData = toolsData.map((tool, index) => {
                        const toolWithAuthors = {
                            ...tool,
                            authors: authorsResponses[index].data,
                        };

                        return toolWithAuthors;
                    });
                    setToolsWithAuthors(toolsWithAuthorsData);
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        axios
            .get('https://api.jeuxsolo.com/univers')
            .then((response) => {
                setUnivers(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const filteredTools = toolsWithAuthors.filter((tool) => {
        const authorName =
            tool.authors && Array.isArray(tool.authors) && tool.authors.length > 0
                ? tool.authors[0].nom_auteur || ''
                : '';

        return (
            tool.titre.toLowerCase().includes(searchTool.toLowerCase()) &&
            authorName.toLowerCase().includes(searchAuthor.toLowerCase())
        );
    });

    return (
        <main>
            <Helmet>
                <title>Outils de création pour jeux solo - JeuxSolo</title>
                <meta
                    name="description"
                    content="Explorez notre sélection d'outils de création pour jeux solo. Construisez vos propres mondes et histoires. Nouveaux outils ajoutés régulièrement."
                />
                <meta name="keywords" content="jeux, solo, jeux solo, outils de création, nouveautés" />
                <meta name="author" content="Guillaume, Baptiste" />
            </Helmet>
            <div className="home-container">
                <div className="container">
                    <div className="search_container">
                        <h1>Outils de création</h1>
                        <div className="search-bar">
                            <input
                                type="text"
                                placeholder="Recherche par outils..."
                                value={searchTool}
                                onChange={(e) => setSearchTool(e.target.value)}
                            />
                            <input
                                type="text"
                                placeholder="Recherche par auteur..."
                                value={searchAuthor}
                                onChange={(e) => setSearchAuthor(e.target.value)}
                            />
                            <select
                                value={selectedUnivers}
                                onChange={(e) => setSelectedUnivers(parseInt(e.target.value))}
                            >
                                <option value="">univers</option>
                                {univers.map((univers) => (
                                    <option key={univers.id_univers} value={univers.id_univers}>
                                        {univers.nom_univers}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    {filteredTools.map((game) => (
                        <div className="game_card_container" key={game.id_outilsDeCreation}>
                            <GameCard
                                id_jeu={game.id_outilsDeCreation}
                                cover={game.cover}
                                titre={game.titre}
                                resume={game.resume}
                                createdAt={game.createdAt}
                                // newGameThreshold={newGameThreshold}
                                category="journaling"
                                difficulte={game.difficulte}
                                authors={
                                    game.authors && Array.isArray(game.authors)
                                        ? game.authors.map((author) => author.nom_auteur)
                                        : []
                                }
                                annee_sortie={game.annee_sortie}
                                univers={univers.find((universItem) => universItem.id_univers === game.id_univers)}
                                lien_affiliation_amazon={game.lien_affiliation_amazon}
                                lien_affiliation_philibert={game.lien_affiliation_philibert}
                                lien_affiliation_drivethrurpg={game.lien_affiliation_drivethrurpg}
                                lien_affiliation_itchio={game.lien_affiliation_itchio}
                                lien_affiliation_externe={game.lien_affiliation_externe}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </main>
    );
}

export default OutilsDeCreation;
