/** @format */

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import GameCard from '../../Components/GameCard/GameCard';
// import AffiliateButton from "../../Components/Buttons/AffiliateButton";
import '../../Styles/HomePage.scss';

function Journaling() {
    const [games, setGames] = useState([]);
    const [searchGame, setSearchGame] = useState('');
    const [searchAuthor, setSearchAuthor] = useState('');
    const [univers, setUnivers] = useState([]);
    const [selectedUnivers, setSelectedUnivers] = useState(null);
    // Date seuil pour déterminer si un jeu est une nouveauté (30 jours)
    const newGameThreshold = new Date();
    newGameThreshold.setDate(newGameThreshold.getDate() - 30);

    useEffect(() => {
        axios
            .get('https://api.jeuxsolo.com/journaling')
            .then((response) => {
                setGames(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        axios
            .get('https://api.jeuxsolo.com/univers')
            .then((response) => {
                setUnivers(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const filteredGames = games.filter((game) => {
        const authorName =
            game.JournalingAuteur && Array.isArray(game.JournalingAuteur) && game.JournalingAuteur.length > 0
                ? game.JournalingAuteur[0].auteur.nom_auteur || ''
                : '';

        return (
            game.titre.toLowerCase().includes(searchGame.toLowerCase()) &&
            authorName.toLowerCase().includes(searchAuthor.toLowerCase()) &&
            (!selectedUnivers || (game.Univers && game.Univers.id_univers === selectedUnivers))
        );
    });

    return (
        <>
            <Helmet>
                <title>Journaling pour jeux solo - JeuxSolo</title>
                <meta
                    name="description"
                    content="Découvrez notre sélection de journaux de jeu pour jeux solo. Gardez une trace de vos aventures et de vos stratégies. Nouveaux journaux ajoutés régulièrement."
                />
                <meta name="keywords" content="jeux, solo, jeux solo, journaling, nouveautés" />
                <meta name="author" content="Guillaume, Baptiste" />
            </Helmet>
            <main className="home-container">
                <div className="container">
                    <div className="search_container">
                        <h1>Journaling</h1>
                        <div className="search-bar">
                            <input
                                type="text"
                                placeholder="Recherche par journaling..."
                                value={searchGame}
                                onChange={(e) => setSearchGame(e.target.value)}
                            />
                            <input
                                type="text"
                                placeholder="Recherche par auteur..."
                                value={searchAuthor}
                                onChange={(e) => setSearchAuthor(e.target.value)}
                            />
                            <select value={selectedUnivers} onChange={(e) => setSelectedUnivers(e.target.value)}>
                                <option value="">univers</option>
                                {univers.map((univers) => (
                                    <option key={univers.id_univers} value={univers.id_univers}>
                                        {univers.nom_univers}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    {filteredGames.map((game) => (
                        <div className="game_card_container">
                            <GameCard
                                key={game.id_journaling}
                                id_jeu={game.id_journaling}
                                cover={game.cover}
                                titre={game.titre}
                                resume={game.resume}
                                createdAt={game.createdAt}
                                newGameThreshold={newGameThreshold}
                                category="jeu-journaling"
                                gameId={game.id_journaling}
                                difficulte={game.difficulte}
                                traduction={game.traduction}
                                authors={
                                    game.JournalingAuteur && Array.isArray(game.JournalingAuteur)
                                        ? game.JournalingAuteur.map((journalAuteur) => journalAuteur.auteur.nom_auteur)
                                        : []
                                }
                                annee_sortie={game.annee_sortie}
                                univers={univers.find((universItem) => universItem.id_univers === game.id_univers)}
                                lien_affiliation_amazon={game.lien_affiliation_amazon}
                                lien_affiliation_philibert={game.lien_affiliation_philibert}
                                lien_affiliation_drivethrurpg={game.lien_affiliation_drivethrurpg}
                                lien_affiliation_itchio={game.lien_affiliation_itchio}
                                lien_affiliation_externe={game.lien_affiliation_externe}
                            />
                        </div>
                    ))}
                </div>
            </main>
        </>
    );
}

export default Journaling;
