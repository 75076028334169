import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faUser,
    faGlobe,
    faCalendar,
    faShield,
} from "@fortawesome/free-solid-svg-icons";
import AffiliateButton from "../../Components/Buttons/AffiliateButton";

import "../../Styles/GameCardHome.scss";

const GameCard = ({
    id_jeu,
    cover,
    titre,
    resume,
    createdAt,
    newGameThreshold,
    category,
    difficulte,
    authors,
    gameId,
    annee_sortie,
    univers,
    traduction,
    lien_affiliation_amazon,
    lien_affiliation_philibert,
    lien_affiliation_drivethrurpg,
    lien_affiliation_itchio,
    lien_affiliation_externe,
    ...otherProps
}) => (
    <div className="wrapper">
        <div className="card">
            <img src={cover} alt={titre} />
            {traduction && (
                <div className="trad_ribbon">Traduit par JeuxSolo</div>
            )}
            {new Date(createdAt) >= newGameThreshold && (
                <div className="ribbon">Nouveauté</div>
            )}

            <div className="info">
                <Link
                    to={`/${category}/${id_jeu}-${titre}`}
                    className="button"
                    rel="noreferrer"
                >
                    <h1>{titre}</h1>
                    <p>{resume}</p>
                </Link>
                <div className="infos_card">
                    <div className="infos_card_up">
                        <div className="difficulty">
                            <FontAwesomeIcon icon={faShield} />
                            <span>{difficulte}</span>
                        </div>
                        <div className="author">
                            <FontAwesomeIcon icon={faUser} />
                            <span>
                                {authors && authors.length > 0
                                    ? authors.join(", ")
                                    : "Auteur inconnu"}
                            </span>
                        </div>
                    </div>
                    <div className="infos_card_down">
                        <div className="release_date">
                            <FontAwesomeIcon icon={faCalendar} />
                            <span>{annee_sortie}</span>
                        </div>
                        <div className="univers">
                            <FontAwesomeIcon icon={faGlobe} />
                            <span>
                                {univers
                                    ? univers.nom_univers
                                    : "Univers non disponible"}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="button-container">
                    <AffiliateButton
                        className="button"
                        jeu={id_jeu}
                        text="Amazon"
                        bgcolor="#ffa600"
                        color="#000000"
                        border="#ffa600"
                        href={lien_affiliation_amazon}
                    />
                    <AffiliateButton
                        className="button"
                        jeu={id_jeu}
                        text="Philibert"
                        bgcolor="#f6871e"
                        color="#000000"
                        border="#f6871e"
                        href={lien_affiliation_philibert}
                    />
                    <AffiliateButton
                        className="button"
                        jeu={id_jeu}
                        text="Dtrpg"
                        bgcolor="#cb0e00"
                        color="#000000"
                        border="#f6871e"
                        href={lien_affiliation_drivethrurpg}
                    />
                    <AffiliateButton
                        className="button"
                        jeu={id_jeu}
                        text="Itch.io"
                        bgcolor="#FFD700 "
                        color="#000000"
                        border="#FFD700 "
                        href={lien_affiliation_itchio}
                    />
                    <AffiliateButton
                        className="button"
                        jeu={id_jeu}
                        text="Externe"
                        bgcolor="#f6871e"
                        color="#000000"
                        border="#f6871e"
                        href={lien_affiliation_externe}
                    />
                </div>
            </div>
        </div>
    </div>
);

export default GameCard;
