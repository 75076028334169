import React from "react";
import { Helmet } from "react-helmet";

import "./LinkFooter.scss";

export default function JeuxDeRoleFooter() {
    return (
        <>
            <Helmet>
                <title>C'est quoi un jeux de rôle solo - JeuxSolo</title>
                <meta
                    name="description"
                    content="Vivez des aventures immersives et passionnantes à votre rythme avec les jeux de rôles solo."
                />
                <meta
                    name="keywords"
                    content="jeux, solo, jeux solo, jeux de rôle, nouveautés, jeux de plateau, livres-jeux, journaling, jeux de stratégie"
                />
                <meta name="author" content="Guillaume, Baptiste" />
            </Helmet>
            <div className="main-para">
                <h1>Jeux de rôle</h1>
                <p>
                    Un jeu de rôle solo a pour support un livre de règles, une
                    feuille de personnage et des dés. Contrairement à un jeu de
                    rôle classique, il n’y a{" "}
                    <span className="bold_text">pas de scénario pré-écrit</span>
                    . Les situations sont générées au hasard, avec un niveau de
                    détail élevé. C’est le joueur qui va donner une cohérence
                    d’ensemble à cette suite de situations, pour construire
                    l’histoire au fur et à mesure .
                </p>

                <p>
                    L’intrigue progresse grâce aux décisions du joueur. La
                    mécanique principale d’un jeu de rôle solo est de répondre à
                    la question : « et maintenant, que faites-vous ? ».{" "}
                    <span className="bold_text">
                        Des tableaux simulent la réponse d’un maître de jeu
                    </span>{" "}
                    au cas par cas, comme s’il était en face du joueur.
                </p>

                <p>
                    Une partie de jeu de rôle solo peut être aussi longue que le
                    joueur le souhaite, et se décliner en de multiples épisodes.
                    Certains joueurs investissent{" "}
                    <span className="bold_text">
                        plusieurs dizaines d’heures de jeu dans une histoire
                    </span>
                    .
                </p>
            </div>
        </>
    );
}
