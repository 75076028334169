import React from "react";
import { Routes, Route } from "react-router-dom";

// ******import Public components;
import PublicLayout from "./PublicLayout";

// ****** Page d'acceuil ******
import HomePage from "../../Pages/HomePage";

// ****** Page d'acceuil ******
import JeuxDeRole from "../../Pages/Main/JeuxDeRole";
import JeuxDePlateau from "../../Pages/Main/JeuxDePlateau";
import Journaling from "../../Pages/Main/Journaling";
import LivresJeux from "../../Pages/Main/LivresJeux";
import Materiel from "../../Pages/Main/Materiel";
import OutilsDeCreation from "../../Pages/Main/OutilsDeCreation";

// ****** Page Détails jeu ID ******
import GameDetails from "../../Components/Games/GameDetails/GameDetails";
import JeuxDePlateauDetails from "../../Pages/Main/JeuxDePlateauDetails";
import JournalingDetails from "../../Pages/Main/JournalingDetails";
import LivresJeuxDetails from "../../Pages/Main/LivresJeuxDetails";
import MaterielDetails from "../../Pages/Main/MaterielDetails";
import OutilsDeCreationDetails from "../../Pages/Main/OutilsDeCreationDetails";

// *****import Form components
import AddGameForm from "../../Components/Form/AddGameForm";
import AddGameBoardForm from "../../Components/Form/AddBoardGameForm";
import AddGameBookForm from "../../Components/Form/AddGameBookForm";
import AddJournalForm from "../../Components/Form/AddJournalForm";
import AddOutilsDeCreationForm from "../../Components/Form/AddOutilsDeCreationForm";
import AddAuthorForm from "../../Components/Form/AddAuthorForm";
import AddUniversForm from "../../Components/Form/AddUniversForm";

// *****import Edit components
import EditGameForm from "../../Components/Edit/EditGameForm";
import EditJeuxDePlateau from "../../Components/Edit/EditJeuxDePlateau";
import EditLivresJeux from "../../Components/Edit/EditLivresJeux";
import EditJournaling from "../../Components/Edit/EditJournaling";
import EditOutilsDeCreation from "../../Components/Edit/EditOutilsDeCreation";

// *****import Footer components
import JeuxSoloProject from "../../Pages/Footer/JeuxSoloProject";
import Contact from "../../Pages/Footer/Contact";
import MentionsLegales from "../../Pages/Footer/MentionsLegales";
import JeuxDeRoleFooter from "../../Pages/Footer/JeuxDeRoleFooter";
import JeuxDePlateauFooter from "../../Pages/Footer/JeuxDePlateauFooter";
import LivresJeuxFooter from "../../Pages/Footer/LivresJeuxFooter";
import JournalingFooter from "../../Pages/Footer/JournalingFooter";

// *****import Error components
import Error404 from "../../error/Error404";

// *****import Construct components
import InBuilding from "../../Components/Construct/InBuilding";

export default function PublicRouter() {
    return (
        <Routes>
            <Route element={<PublicLayout />}>
                <Route index element={<HomePage />} />

                {/* *******************Routes Utilisateurs non-connecté************************** */}
                <Route path="/" element={<HomePage />} />

                {/* *********************Routes construction ********************************* */}

                <Route path="/in-building" element={<InBuilding />} />

                {/* *******************Routes Catégories************************** */}
                <Route path="/jeux-de-role" element={<JeuxDeRole />} />
                <Route path="/jeux-de-plateau" element={<JeuxDePlateau />} />
                <Route path="/journaling" element={<Journaling />} />
                <Route path="/livres-jeux" element={<LivresJeux />} />
                <Route
                    path="/outils-de-creation"
                    element={<OutilsDeCreation />}
                />
                <Route path="/materiel" element={<Materiel />} />

                {/* *******************Routes Détails jeux************************** */}

                <Route path="/jeux/:id_jeu" element={<GameDetails />} />
                <Route
                    path="/jeu-de-plateau/:id_jeu"
                    element={<JeuxDePlateauDetails />}
                />
                <Route
                    path="/jeu-journaling/:id_journaling"
                    element={<JournalingDetails />}
                />
                <Route
                    path="/livres-jeux/:id_livreJeux"
                    element={<LivresJeuxDetails />}
                />
                <Route
                    path="/outils-de-creation/:id_outilsDeCreation"
                    element={<OutilsDeCreationDetails />}
                />
                <Route
                    path="/materiel/:id_materiel"
                    element={<MaterielDetails />}
                />

                {/* *********************Routes admin formulaire ajout*************************** */}

                <Route path="/form/jeux-de-role" element={<AddGameForm />} />
                <Route
                    path="/form/jeux-de-plateau"
                    element={<AddGameBoardForm />}
                />
                <Route path="/form/livres-jeux" element={<AddGameBookForm />} />
                <Route path="/form/journaling" element={<AddJournalForm />} />
                <Route
                    path="/form/outils-de-creation"
                    element={<AddOutilsDeCreationForm />}
                />
                <Route path="/form/auteur" element={<AddAuthorForm />} />
                <Route path="/form/univers" element={<AddUniversForm />} />
                <Route path="/form/jeuxdeplateau" element={<JeuxDePlateau />} />

                {/* *********************Routes admin formulaire édition********************* */}

                <Route
                    path="/form/edit-jeux-de-role"
                    element={<EditGameForm />}
                />
                <Route
                    path="/form/edit-jeux-de-plateau"
                    element={<EditJeuxDePlateau />}
                />
                <Route
                    path="/form/edit-journaling"
                    element={<EditJournaling />}
                />
                <Route
                    path="/form/edit-livres-jeu"
                    element={<EditLivresJeux />}
                />
                <Route
                    path="/form/edit-outils-de-creation"
                    element={<EditOutilsDeCreation />}
                />

                {/* *********************Routes liens footer********************************* */}

                <Route
                    path="/jeux-solo-project"
                    element={<JeuxSoloProject />}
                />
                <Route path="/contact" element={<Contact />} />
                <Route path="/mentions-legales" element={<MentionsLegales />} />
                <Route
                    path="/jeu-de-role-tuto"
                    element={<JeuxDeRoleFooter />}
                />
                <Route path="/livre-jeux-tuto" element={<LivresJeuxFooter />} />
                <Route path="/journaling-tuto" element={<JournalingFooter />} />
                <Route
                    path="/jeu-de-plateau-tuto"
                    element={<JeuxDePlateauFooter />}
                />

                <Route path="*" element={<Error404 />} />
            </Route>
        </Routes>
    );
}
