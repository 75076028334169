import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useInterval } from "react-use";
import GameCard from "../../Components/GameCard/GameCard";

import "../../Styles/HomePage.scss";

function JeuxDePlateau() {
    const [games, setGames] = useState([]);
    const [authors, setAuthors] = useState({});
    const [searchAuthor, setSearchAuthor] = useState("");
    const [searchGame, setSearchGame] = useState("");
    const [selectedUnivers, setSelectedUnivers] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [univers, setUnivers] = useState([]);

    useEffect(() => {
        axios
            .get("https://api.jeuxsolo.com/jeuxdeplateau")
            .then((response) => {
                setGames(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        if (games.length > 0) {
            const authorIds = games
                .filter((game) => game.JeuxDePlateauAuteur.length > 0)
                .map((game) => game.JeuxDePlateauAuteur[0].auteur.id_auteur);

            if (authorIds.length > 0) {
                axios
                    .get(
                        `https://api.jeuxsolo.com/auteurs?ids=${authorIds.join(
                            ",",
                        )}`,
                    )
                    .then((response) => {
                        const authorData = response.data.reduce(
                            (acc, author) => {
                                acc[author.id_auteur] = author.nom_auteur;
                                return acc;
                            },
                            {},
                        );
                        setAuthors(authorData);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        }
    }, [games]);

    useEffect(() => {
        axios
            .get("https://api.jeuxsolo.com/univers")
            .then((response) => {
                setUnivers(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const handleSearchAuthor = (event) => {
        setSearchAuthor(event.target.value);
    };

    const handleClearSearch = () => {
        setSearchAuthor("");
        setSearchGame("");
    };

    const handleSearchGame = (event) => {
        setSearchGame(event.target.value);

        if (event.target.value !== "") {
            setSuggestions(
                games
                    .filter((game) =>
                        game.titre
                            .toLowerCase()
                            .startsWith(event.target.value.toLowerCase()),
                    )
                    .slice(0, 5), // Limit the suggestions to the top 5
            );
        } else {
            setSuggestions([]);
        }
    };

    const filteredGames = games.filter((game) => {
        const authorName =
            authors[game.JeuxDePlateauAuteur[0]?.auteur.id_auteur] || "";
        return (
            game.titre.toLowerCase().includes(searchGame.toLowerCase()) &&
            authorName.toLowerCase().includes(searchAuthor.toLowerCase()) &&
            (!selectedUnivers || game.Univers?.id_univers == selectedUnivers)
        );
    });

    useInterval(() => {
        axios
            .get("https://api.jeuxsolo.com/jeuxdeplateau")
            .then((response) => {
                setGames(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, 10000);

    // Date seuil pour déterminer si un jeu est une nouveauté (30 jours)
    const newGameThreshold = new Date();
    newGameThreshold.setDate(newGameThreshold.getDate() - 30);

    return (
        <>
            <Helmet>
                <title>Découvrez les jeux de plateau solo - JeuxSolo</title>
                <meta
                    name="description"
                    content="Parcourez notre gamme de jeux de plateau solo. Jouez à votre rythme et profitez de stratégies complexes sans adversaire. Nouveaux jeux ajoutés régulièrement."
                />
                <meta
                    name="keywords"
                    content="jeux, solo, jeux solo, jeux de plateau, nouveautés, stratégie"
                />
                <meta name="author" content="Guillaume, Baptiste" />
            </Helmet>
            <main className="home-container">
                <div className="container">
                    <div className="search_container">
                        <h1>Jeux de plateau</h1>
                        <div className="search-bar">
                            <input
                                type="text"
                                placeholder="Recherche par auteur..."
                                value={searchAuthor}
                                onChange={handleSearchAuthor}
                            />
                            {searchAuthor && (
                                <button onClick={handleClearSearch}>
                                    Clear
                                </button>
                            )}
                            <input
                                type="text"
                                placeholder="Recherche par jeu..."
                                value={searchGame}
                                onChange={handleSearchGame}
                            />
                            <select
                                value={selectedUnivers}
                                onChange={(e) =>
                                    setSelectedUnivers(e.target.value)
                                }
                            >
                                <option value="">univers</option>
                                {univers.map((univers) => (
                                    <option
                                        key={univers.id_univers}
                                        value={univers.id_univers}
                                    >
                                        {univers.nom_univers}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="container">
                        {filteredGames.map((game) => (
                            <div className="game_card_container">
                                <GameCard
                                    key={game.id_jeuDePlateau}
                                    id_jeu={game.id_jeuDePlateau}
                                    cover={game.cover}
                                    titre={game.titre}
                                    resume={game.resume}
                                    createdAt={game.createdAt}
                                    newGameThreshold={newGameThreshold}
                                    category="jeu-de-plateau"
                                    gameId={game.id_jeuDePlateau}
                                    difficulte={game.difficulte}
                                    authors={game.JeuxDePlateauAuteur.map(
                                        (jeuAuteur) =>
                                            authors[jeuAuteur.auteur.id_auteur],
                                    )}
                                    annee_sortie={game.annee_sortie}
                                    univers={univers.find(
                                        (universItem) =>
                                            universItem.id_univers ===
                                            game.id_univers,
                                    )}
                                    traduction={game.traduction}
                                    lien_affiliation_amazon={
                                        game.lien_affiliation_amazon
                                    }
                                    lien_affiliation_philibert={
                                        game.lien_affiliation_philibert
                                    }
                                    lien_affiliation_drivethrurpg={
                                        game.lien_affiliation_drivethrurpg
                                    }
                                    lien_affiliation_itchio={
                                        game.lien_affiliation_itchio
                                    }
                                    lien_affiliation_externe={
                                        game.lien_affiliation_externe
                                    }
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </main>
        </>
    );
}

export default JeuxDePlateau;
