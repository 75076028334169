import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button, Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import "../../Styles/EditGameForm.scss";

const EditJeuxDePlateauForm = () => {
    const navigate = useNavigate();
    const [games, setGames] = useState([]);
    const [currentGame, setCurrentGame] = useState(null);
    const [show, setShow] = useState(false);
    const { register, handleSubmit, setValue } = useForm();
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [authors, setAuthors] = useState([]);
    const [univers, setUnivers] = useState([]);

    useEffect(() => {
        const fetchGames = async () => {
            try {
                const response = await axios.get(
                    "https://api.jeuxsolo.com/jeuxdeplateau",
                );
                setGames(response.data);
            } catch (error) {
                console.error(
                    "Erreur lors de la récupération des données du jeu : ",
                    error,
                );
            }
        };

        fetchGames();
    }, []);

    useEffect(() => {
        if (currentGame) {
            for (let field in currentGame) {
                setValue(field, currentGame[field]);
            }
        }
    }, [currentGame, setValue]);

    useEffect(() => {
        const fetchAuthors = async () => {
            try {
                const response = await axios.get(
                    "https://api.jeuxsolo.com/auteurs",
                );
                setAuthors(response.data);
            } catch (error) {
                console.error(
                    "Erreur lors de la récupération des auteurs : ",
                    error,
                );
            }
        };
        const fetchUnivers = async () => {
            try {
                const response = await axios.get(
                    "https://api.jeuxsolo.com/univers",
                );
                setUnivers(response.data);
            } catch (error) {
                console.error(
                    "Erreur lors de la récupération des univers : ",
                    error,
                );
            }
        };
        fetchAuthors();
        fetchUnivers();
    }, []);

    const handleClose = () => setShow(false);

    const handleShow = (game) => {
        setCurrentGame(game);
        setShow(true);
    };

    const onSubmit = async (data) => {
        try {
            await axios.put(
                `https://api.jeuxsolo.com/admin/jeuxdeplateau/${currentGame.id_jeuDePlateau}`,
                data,
            );
            setMessage("Jeux mis à jour avec succès.");
            setError("");
            handleClose();
        } catch (error) {
            setError(
                "Erreur lors de la mise à jour du jeu : " + error.response.data,
            );
            setMessage("");
        }
    };
    const onDelete = async (id) => {
        // Demandez une confirmation avant de supprimer
        const confirmDelete = window.confirm(
            "Voulez-vous vraiment effacer ce jeu ?",
        );

        if (confirmDelete) {
            try {
                await axios.delete(
                    `https://api.jeuxsolo.com/admin/jeuxdeplateau/${id}`,
                );
                // Mettre à jour la liste des jeux après la suppression
                setGames(games.filter((game) => game.id_jeuDePlateau !== id));
            } catch (error) {
                console.error("Erreur lors de la suppression du jeu: ", error);
            }
        }
    };

    return (
        <>
            <nav className="form-button">
                <select onChange={(e) => navigate(e.target.value)}>
                    <option value="">Ajouter un jeu</option>
                    <option value="/form/jeux-de-role">Jeux de rôle</option>
                    <option value="/form/jeux-de-plateau">
                        Jeux de plateau
                    </option>
                    <option value="/form/livres-jeux">Livres-Jeux</option>
                    <option value="/form/journaling">Journaling</option>
                    <option value="/form/outils-de-creation">
                        Outils de création
                    </option>
                    <option value="/form/materiel">Matériel</option>
                    <option value="/form/auteur">Créer un auteur</option>
                    <option value="/form/univers">Créer un Univers</option>
                </select>
            </nav>

            <nav className="edit-form-buttons">
                <select onChange={(e) => navigate(e.target.value)}>
                    <option value="">Editer un jeu</option>
                    <option value="/form/edit-jeux-de-role">
                        Edit Jeux de rôle
                    </option>
                    <option value="/form/edit-jeux-de-plateau">
                        Edit Jeux de plateau
                    </option>
                    <option value="/form/edit-livres-jeu">
                        Edit Livres-Jeux
                    </option>
                    <option value="/form/edit-journaling">
                        Edit Journaling
                    </option>
                    <option value="/form/edit-outils-de-creation">
                        Edit Outils de création
                    </option>
                    <option value="/form/materiel">Edit Matériel</option>
                    <option value="/form/auteur">Edit Créer un auteur</option>
                    <option value="/form/univers">Edit Créer un Univers</option>
                </select>
            </nav>
            <h1>Jeux de plateau</h1>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Titre</th>
                        <th>Modifier</th>
                        <th>Supprimer</th>
                    </tr>
                </thead>
                <tbody>
                    {games.map((game) => (
                        <tr key={game.id_jeu}>
                            <td>{game.titre}</td>
                            <td>
                                <Button
                                    variant="outline-secondary"
                                    onClick={() => handleShow(game)}
                                >
                                    <i
                                        className="fa fa-pencil"
                                        style={{ color: "blue" }}
                                    />
                                </Button>
                            </td>
                            <td>
                                <Button
                                    variant="outline-danger"
                                    onClick={() =>
                                        onDelete(game.id_jeuDePlateau)
                                    }
                                >
                                    <i
                                        className="fa fa-trash"
                                        style={{
                                            color: "red",
                                        }}
                                    />
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            {currentGame && (
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Modifier le jeu</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div>
                                <input
                                    {...register("titre")}
                                    placeholder="Titre"
                                />
                            </div>
                            <div>
                                <input
                                    {...register("annee_sortie")}
                                    placeholder="Année de sortie"
                                />
                            </div>
                            <div>
                                <input
                                    {...register("cover")}
                                    placeholder="Couverture"
                                />
                            </div>
                            <div>
                                <input
                                    {...register("difficulte")}
                                    placeholder="Difficulté"
                                />
                            </div>
                            <div>
                                <textarea
                                    {...register("description")}
                                    placeholder="Description"
                                />
                            </div>
                            <div>
                                <textarea
                                    {...register("resume")}
                                    placeholder="Résumé"
                                />
                            </div>
                            <div>
                                <select {...register("id_auteur")}>
                                    {authors.map((author) => (
                                        <option
                                            key={author.id}
                                            value={author.id}
                                        >
                                            {author.nom_auteur}{" "}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <select {...register("id_univers")}>
                                    {univers.map((univer) => (
                                        <option
                                            key={univer.id_univers}
                                            value={univer.id_univers}
                                        >
                                            {univer.nom_univers}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <input
                                    {...register("lien_affiliation_amazon")}
                                    placeholder="Lien d'affiliation Amazon"
                                />
                            </div>
                            <div>
                                <input
                                    {...register("lien_affiliation_philibert")}
                                    placeholder="Lien d'affiliation Philibert"
                                />
                            </div>
                            <div>
                                <input
                                    {...register("lien_affiliation_itchio")}
                                    placeholder="Lien d'affiliation Itch.io"
                                />
                            </div>
                            <div>
                                <input
                                    {...register(
                                        "lien_affiliation_drivethrurpg",
                                    )}
                                    placeholder="Lien d'affiliation DriveThruRPG"
                                />
                            </div>
                            <div>
                                <input
                                    {...register("lien_affiliation_externe")}
                                    placeholder="Lien d'affiliation externe"
                                />
                            </div>
                            <Button variant="primary" type="submit">
                                Valider les modifications
                            </Button>
                        </form>
                        {message && <p className="message">{message}</p>}
                        {error && <p className="error">{error}</p>}
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
};

export default EditJeuxDePlateauForm;
