import React from "react";
import { Helmet } from "react-helmet";

import "./LinkFooter.scss";

export default function JeuxDePlateauFooter() {
    return (
        <>
            <Helmet>
                <title>C'est quoi un jeux de plateau - JeuxSolo</title>
                <meta
                    name="description"
                    content="Vivez des aventures immersives et passionnantes à votre rythme avec les jeux de plateau solo."
                />
                <meta
                    name="keywords"
                    content="jeux, solo, jeux solo, nouveautés, jeux de plateau, jeux de stratégie"
                />
                <meta name="author" content="Guillaume, Baptiste" />
            </Helmet>
            <div className="main-para">
                <h1>Jeux de plateau</h1>
                <p>
                    Un jeu de plateau a pour support principal un plateau (fixe,
                    ou constitué de tuiles ou de cartes), ainsi que des pions
                    (jetons ou figurines). Le hasard intervient fréquemment dans
                    la plupart des jeux de plateau, sous la forme de lancers de
                    dés ou de tirages de cartes.
                </p>

                <p>
                    A la différence d’un jeu de rôle, la narration d’un jeu de
                    plateau reste relativement secondaire. La part belle est
                    donnée aux mécanismes du jeu, et aux stratégies que le
                    joueur devra utiliser pour gagner. Un jeu de plateau solo
                    permet au joueur de se confronter à{" "}
                    <span className="bold_text">
                        un ou plusieurs adversaires fictifs, dont le
                        comportement sera automatisé par le jeu
                    </span>
                    .
                </p>
            </div>
        </>
    );
}
