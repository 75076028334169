import React, { useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../Styles/Header.scss";

function Header() {
    const [expanded, setExpanded] = useState(false);

    const handleNavLinkClick = () => {
        setExpanded(false);
    };

    return (
        <Navbar
            className="main_container p-0"
            bg="dark"
            variant="dark"
            expand="md"
            expanded={expanded}
        >
            <div className="logo_main_container">
                <Link to="/">
                    <Navbar.Brand className="logo_container">
                        <img
                            src="/images/logo_jeuxsolo_header.png"
                            // src="https://www.de-en-ligne.fr/img/d%C3%A9s/20-faces/de-20-faces.png"
                            alt="Logo"
                            className="logo"
                        />

                        <h1 className="title">
                            JeuxSolo.com
                            <br />
                            <span>L'antre des jeux solo en français</span>
                        </h1>
                    </Navbar.Brand>
                </Link>
                
            </div>
            <Navbar.Toggle
                className="custom-toggler"
                aria-controls="basic-navbar-nav"
                onClick={() => setExpanded(!expanded)}
            />
            <Navbar.Collapse className="navbar_container" id="basic-navbar-nav">
                <Nav className="ms-auto">
                    <ul className="nav-links">
                        <li>
                            <Link
                                to="/"
                                className="nav-link"
                                onClick={handleNavLinkClick}
                            >
                                <img
                                    className="nav-icon"
                                    src="/images/home.png"
                                    alt="description1"
                                    style={{ marginLeft: "10px" }}
                                />
                                Accueil
                            </Link>
                        </li>

                        <li>
                            <Link
                                to="/jeux-de-plateau"
                                className="nav-link"
                                onClick={handleNavLinkClick}
                            >
                                <img
                                    className="nav-icon"
                                    src="/images/jeux_plateau.png"
                                    alt="description1"
                                    style={{ marginLeft: "10px" }}
                                />
                                Jeux de plateau
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/livres-jeux"
                                className="nav-link"
                                onClick={handleNavLinkClick}
                            >
                                <img
                                    className="nav-icon"
                                    src="/images/livres_jeux.png"
                                    alt="description1"
                                    style={{ marginLeft: "10px" }}
                                />
                                Livres-Jeux
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/jeux-de-role"
                                className="nav-link"
                                onClick={handleNavLinkClick}
                            >
                                <img
                                    className="nav-icon"
                                    src="/images/jeux_role.png"
                                    alt="description1"
                                    style={{ marginLeft: "10px" }}
                                />
                                Jeux de rôle
                            </Link>
                        </li>

                        <li>
                            <Link
                                to="/journaling"
                                className="nav-link"
                                onClick={handleNavLinkClick}
                            >
                                <img
                                    className="nav-icon"
                                    src="/images/journaling.png"
                                    alt="description1"
                                    style={{ marginLeft: "10px" }}
                                />
                                Journaling
                            </Link>
                        </li>
                        <li className="nav-link-blog">
                            <Link
                                to="https://jeuxsolo.wixsite.com/jeuxsoloblog"
                                target="_blank"
                                className="nav-link"
                                onClick={handleNavLinkClick}
                            >
                                <img
                                    className="nav-icon"
                                    src="/images/coffre.png"
                                    alt="description1"
                                    style={{ marginLeft: "10px" }}
                                />
                                Blog
                            </Link>
                        </li>
                    </ul>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default Header;
