import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";

import "./App.scss";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

import PublicRouter from "../src/Router/PublicRouter/PublicRouter";
import AdminRouter from "../src/Router/AdminRouter/AdminRouter";


const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

function App() {
React.useEffect(() => {
   var _mtm = window._mtm = window._mtm || [];
   _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
   var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
   g.async=true; g.src='https://cdn.matomo.cloud/jeuxsolo.matomo.cloud/container_g1ghcT49.js'; s.parentNode.insertBefore(g,s);
  }, [])

    return (
        <>
            {/* Les routes sont gérées par le composant <BrowserRouter> de react-router-dom */}
            <BrowserRouter>
                <ScrollToTop />
                {/* Les routes sont gérées par le composant <Routes> de react-router-dom */}
                <Routes>
                    {/* Les routes sont gérées par le composant <Route> de react-router-dom */}
                    <Route path="/*" element={<PublicRouter />} />
                    <Route path="/admin" element={<AdminRouter />} />
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;
