import React from "react";
import { Helmet } from "react-helmet";

import "./LinkFooter.scss";

export default function JournalingFooter() {
    return (
        <>
            <Helmet>
                <title>C'est quoi un livre-jeux - JeuxSolo</title>
                <meta
                    name="description"
                    content="Vivez des aventures immersives et passionnantes à votre rythme avec les journaling jeuxsolo !."
                />
                <meta
                    name="keywords"
                    content="jeux, solo, jeux solo, nouveautés, journaling, jeux de stratégie"
                />
                <meta name="author" content="Guillaume, Baptiste" />
            </Helmet>
            <div className="main-para">
                <h1>Journaling</h1>
                <p>
                    Un jeu de journaling propose de{" "}
                    <span className="bold_text">
                        rédiger le journal intime d’un personnage
                    </span>{" "}
                    fictif. Le jeu donne comme amorce un contexte précis. Les
                    situations vécues par le personnage sont ensuite tirées au
                    hasard dans une liste. Elles sont davantage orientées sur
                    les ressentis du personnage que sur ses actions. Ces
                    ressentis peuvent faire écho à des questionnements
                    personnels du joueur. Un jeu de journaling est donc souvent
                    perçu comme plus projectif qu’un jeu de rôle classique.
                </p>

                <p>
                    Une partie de journaling peut être organisée en{" "}
                    <span className="bold_text">
                        courtes sessions quotidiennes
                    </span>{" "}
                    pendant une certaine période. Cela peut nouer un sentiment
                    d’intimité durable avec le personnage fictif que l’on
                    incarne dans le jeu.
                </p>
            </div>
        </>
    );
}
