import React from "react";
import { Link } from "react-router-dom";
import "../error/Error404.scss";

function ErrorPage() {
    return (
        <div id="oopss">
            <div id="error-text">
                <img
                    src="https://cdn.rawgit.com/ahmedhosna95/upload/1731955f/sad404.svg"
                    alt="404"
                />
                <span>404 PAGE</span>
                <p className="p-a">La page que vous demandez n'existe pas</p>

                <Link to="/" className="nav-link">
                    <p className="p-b">... Retour à l'accueil</p>
                </Link>
            </div>
        </div>
    );
}

export default ErrorPage;
