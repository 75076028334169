/** @format */

import React from 'react';
import { Helmet } from 'react-helmet';

import './LinkFooter.scss';

export default function JeuxSoloProject() {
    return (
        <div className="main-para">
            <Helmet>
                <title>JeuxSolo - Votre base de donnée pour les jeux solo</title>
                <meta
                    name="description"
                    content="JeuxSolo propose une base de donnée organisée et sélective de jeux solo. Nous offrons des traductions de qualité de jeux jamais traduits à ce jour. Visitez notre site pour découvrir le dernier jeu traduit !"
                />
                <meta
                    name="keywords"
                    content="jeux, solo, jeux solo, jeux de rôle, jeux de plateau, livres-jeux, journaling, jeux de stratégie, traductions de jeux"
                />
                <meta name="author" content="Guillaume De pereyra, Baptiste Fleury" />
            </Helmet>

            <h1>Bienvenue sur JeuxSolo !</h1>
            <p>
                Créé en mai 2023 par Guillaume (rédacteur, traducteur) et Baptiste (web designer), ce site a pour
                vocation de vous proposer une base de donnée organisée et sélective de jeux solo.
            </p>
            <h2>Envie de jouer ?</h2>
            <p>
                Imaginer une histoire riche en rebondissements, partir à l’aventure dans la peau d’un personnage, lancer
                des dés : vous entendez cet appel de l’odyssée et du jeu ! Mais comment faire avec si peu de temps libre
                et des amis qui ont leurs propres obligations, ou qui ne veulent pas se lancer dans ce jeu fabuleux qui
                implique des heures d’investissement ? Il y a bien des clubs de jeux, mais avouons-le, les « grosses
                boîtes » ne sont quasi jamais ouvertes, et Fredo le Paladin ne vient qu’une fois sur deux.
            </p>
            <br></br>
            <p>
                Nostalgique, vous pensez à ce stéréotype du groupe d’adolescents se retrouvant tous les week-ends dans
                une cave pour incarner mages et guerriers, héros passionnés d’une aventure extraordinaire ! Chassez ces
                regrets, l’ami(e) ! Il existe aujourd’hui une large gamme de jeux permettant de vivre l’aventure en
                solitaire, avec l’avantage indéniable de pouvoir consacrer le temps que vous voulez à l’univers qui vous
                fait envie.
            </p>
            <h2>Qu'est ce qu'un jeu solo ?</h2>
            <p>
                Les jeux de rôle solo proposent des « émulateurs de maître du jeu » qui répondront à vos questions ou
                vous proposeront toutes sortes de situations comme le ferait un véritable maître de jeu, moyennant
                quelques lancers de dés sur des tableaux. Les jeux de plateau solo permettent d’incarner un ou plusieurs
                personnages jouant contre le jeu, qu’il s’agisse de salles et de monstres générés par un système
                astucieux, ou d’une gestion de ressources contre un adversaire piloté par des cartes. Les livres-jeux
                sont des romans interactifs dont la suite dépend de vos choix, et le journaling vous propose d’écrire le
                journal intime d’une personne fictive que vous incarnez dans un univers alternatif.
            </p>
            <br></br>
            <p>
                Vous développerez avec ces jeux des histoires réellement profondes, grâce à la libre expression de
                l’imagination personnelle, une expérience intime qu’il est très difficile de retrouver dans le jeu
                collectif. Vous pouvez prendre le temps qu’il vous faut pour développer les directions narratives, les
                parcours initiatiques, les romances ou les stratégies qui vous intéressent. Certains jeux solo sont tout
                à fait accessible aux débutants, d’autres s’adressent davantage aux joueurs aguerris. Les critiques de
                JeuxSolo sont là pour vous aiguiller, en fonction de votre expérience et de vos goûts !
            </p>
            <h2>Traductions exclusives !</h2>
            <p>
                Do you speak English ? Bien que le jeu solo commence à prendre son essor en France (de plus en plus de
                gros éditeurs s’y intéressent), il est en pleine explosion aux Etats-Unis et en Angleterre depuis une
                quinzaine d’années. Disons-le clairement : nous avons un train de retard. Du fait que de nombreux jeux
                sont auto-édités et que les traductions coûtent cher, l’offre francophone reste restreinte.
            </p>
            <br></br>
            <p>
                Mais c’est aussi l’un des atouts majeurs de JeuxSolo : anglophone et passionné de longue date de
                livres-jeux et de jeux de toutes sortes, Guillaume est en contact régulier avec des auteurs étrangers,
                et rend un service bénévole de traductions de qualité. Cela nous permet de vous proposer en exclusivité
                des jeux jamais traduits à ce jour – et parfois encore méconnus des communautés francophones. Visitez
                régulièrement notre site pour découvrir le dernier jeu traduit !
            </p>

            <h2>Comment soutenir le site ?</h2>
            <p>
                {' '}
                Nous vous proposons avec chaque article des liens affiliés pour acheter les jeux ou le matériel qui vous
                intéressent. Si vous achetez ce produit après avoir cliqué sur notre lien, nous serons réumérés à
                hauteur de 5% de la vente. C’est le meilleur moyen de nous soutenir. Cette rémunération nous permettra
                de payer l’hébergement du site, et constitue une reconnaissance du travail chronophage que représentent
                la traduction des jeux, la rédaction des articles, et la maintenance du site. Ce site est la ludothèque
                dont vous êtes le héros ou l’héroïne, et si vous avez des questions ou des envies à partager, n’hésitez
                pas à nous contacter
            </p>
            <br></br>
            <div className="team">
                <div className="guillaume">
                    <h5>Guillaume</h5>

                    <img src="/images/photo_guillaume.webp" alt="photo_guillaume" />
                    <ul>
                        <li>Créateur de JeuxSolo</li>
                        {/* <li>Rédacteur</li> */}
                        <li>Traducteur</li>
                        <li>Contact pour partenariats</li>
                    </ul>
                </div>
                <div className="alexandre">
                    <h5>Alexandre</h5>

                    <img src="/images/alexandre.jpg" alt="photo_alexandre" />
                    <ul>
                        {/* <li>Correcteur</li> */}
                        <li>Rédacteur</li>
                        <li>Traducteur</li>
                    </ul>
                </div>
                <div className="baptiste">
                    <h5>Baptiste</h5>

                    <img src="/images/baptiste.jpg" alt="photo_baptiste" />
                    <ul>
                        <li>Web designer</li>
                        <li>Créateur de JeuxSolo</li>
                        <li>Contact technique</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}
