import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import InfoModal from "../Modals/InfoModal";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";

import "./Form.scss";

function AddJournalForm() {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
    } = useForm();

    const [univers, setUnivers] = useState([]);

    const [auteurs, setAuteurs] = useState([]);

    const description = watch("description");

    useEffect(() => {
        const fetchUnivers = async () => {
            const response = await axios.get(
                "https://api.jeuxsolo.com/univers",
            );

            setUnivers(response.data);
        };
        const fetchAuteurs = async () => {
            const response = await axios.get(
                "https://api.jeuxsolo.com/auteurs",
            );

            setAuteurs(response.data);
        };

        fetchUnivers();
        fetchAuteurs();
    }, []);

    const onSubmit = async (data) => {
        try {
            const jeuToSubmit = {
                ...data,
                description: data.description,
                id_auteur: parseInt(data.id_auteur),
                id_univers: parseInt(data.id_univers),
                difficulte: data.difficulte,
                traduction: data.traduction === "true" ? true : false, // conversion de la valeur en booléen
            };

            await axios.post(
                "https://api.jeuxsolo.com/admin/journaling",
                jeuToSubmit,
            );
            reset();
            alert("Le journaling a bien été créé!");
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <nav className="form-button">
                <select onChange={(e) => navigate(e.target.value)}>
                    <option value="">Ajouter un jeu</option>
                    <option value="/form/jeux-de-role">Jeux de rôle</option>
                    <option value="/form/jeux-de-plateau">
                        Jeux de plateau
                    </option>
                    <option value="/form/livres-jeux">Livres-Jeux</option>
                    <option value="/form/journaling">Journaling</option>
                    <option value="/form/outils-de-creation">
                        Outils de création
                    </option>
                    <option value="/form/materiel">Matériel</option>
                    <option value="/form/auteur">Créer un auteur</option>
                    <option value="/form/univers">Créer un Univers</option>
                </select>
            </nav>

            <nav className="edit-form-buttons">
                <select onChange={(e) => navigate(e.target.value)}>
                    <option value="">Editer un jeu</option>
                    <option value="/form/edit-jeux-de-role">
                        Edit Jeux de rôle
                    </option>
                    <option value="/form/edit-jeux-de-plateau">
                        Edit Jeux de plateau
                    </option>
                    <option value="/form/edit-livres-jeu">
                        Edit Livres-Jeux
                    </option>
                    <option value="/form/edit-journaling">
                        Edit Journaling
                    </option>
                    <option value="/form/edit-outils-de-creation">
                        Edit Outils de création
                    </option>
                    <option value="/form/materiel">Edit Matériel</option>
                    <option value="/form/auteur">Edit Créer un auteur</option>
                    <option value="/form/univers">Edit Créer un Univers</option>
                </select>
            </nav>
            <form className="game-form" onSubmit={handleSubmit(onSubmit)}>
                <h1>Ajouter un jeu de journaling</h1>

                <label>
                    Titre
                    <input
                        type="text"
                        {...register("titre", { required: true })}
                    />
                    {errors.titre && <p>Ce champ est requis</p>}
                </label>
                <label>
                    Année de sortie
                    <input
                        type="text"
                        {...register("annee_sortie", { required: true })}
                    />
                    {errors.annee_sortie && <p>Ce champ est requis</p>}
                </label>
                <label>
                    Difficulté
                    <input
                        type="text"
                        {...register("difficulte", { required: true })}
                    />
                </label>
                <label>
                    Couverture
                    <input
                        type="text"
                        placeholder="Entrez un lien image depuis votre serveur.jpeg.png.webp, etc....."
                        {...register("cover", { required: true })}
                    />
                    {errors.cover && <p>Ce champ est requis</p>}
                </label>
                <label>
                    Résumé
                    <textarea
                        placeholder="Entrez un cours résumé du jeu"
                        {...register("resume", { required: true })}
                    />
                    {errors.resume && <p>Ce champ est requis</p>}
                </label>
                <label>
                    Description
                    <textarea
                        placeholder="Entrez la critique du jeu formatée en HTML ! Liens d'aide si dessous"
                        {...register("description", { required: true })}
                    />
                    {/* Display parsed description */}
                    {parse(description || "")}
                    {errors.description && <p>Ce champ est requis</p>}
                    <InfoModal />
                </label>
                <label>
                    Auteur
                    <select {...register("id_auteur", { required: true })}>
                        {auteurs.map((auteur) => (
                            <option
                                key={auteur.id_auteur}
                                value={auteur.id_auteur}
                            >
                                {auteur.nom_auteur}
                            </option>
                        ))}
                    </select>
                    {errors.id_auteur && <p>Ce champ est requis</p>}
                </label>
                <label>
                    Univers
                    <select {...register("id_univers", { required: true })}>
                        {univers.map((univers) => (
                            <option
                                key={univers.id_univers}
                                value={univers.id_univers}
                            >
                                {univers.nom_univers}
                            </option>
                        ))}
                    </select>
                    {errors.id_univers && <p>Ce champ est requis</p>}
                </label>
                <label>
                    Traduction
                    <label>
                        <input
                            type="radio"
                            value="true"
                            {...register("traduction", { required: true })}
                        />
                        Oui
                    </label>
                    <label>
                        <input
                            type="radio"
                            value="false"
                            {...register("traduction", { required: true })}
                        />
                        Non
                    </label>
                    {errors.traduction && <p>Ce champ est requis</p>}
                </label>
                <label>
                    Lien d'affiliation Amazon
                    <input
                        type="text"
                        {...register("lien_affiliation_amazon")}
                    />
                    {errors.lien_affiliation_amazon && (
                        <p>Ce champ est requis</p>
                    )}
                </label>
                <label>
                    Lien d'affiliation Philibert
                    <input
                        type="text"
                        {...register("lien_affiliation_philibert")}
                    />
                    {errors.lien_affiliation_philibert && (
                        <p>Ce champ est requis</p>
                    )}
                </label>
                <label>
                    Lien d'affiliation Itchio
                    <input
                        type="text"
                        {...register("lien_affiliation_itchio")}
                    />
                    {errors.lien_affiliation_itchio && (
                        <p>Ce champ est requis</p>
                    )}
                </label>
                <label>
                    Lien d'affiliation DrivethruRPG
                    <input
                        type="text"
                        {...register("lien_affiliation_drivethrurpg")}
                    />
                    {errors.lien_affiliation_drivethrurpg && (
                        <p>Ce champ est requis</p>
                    )}
                </label>
                <label>
                    Lien d'affiliation externe
                    <input
                        type="text"
                        {...register("lien_affiliation_externe")}
                    />
                    {errors.lien_affiliation_externe && (
                        <p>Ce champ est requis</p>
                    )}
                </label>
                <button type="submit">Ajouter le jeu</button>
            </form>
        </>
    );
}

export default AddJournalForm;
